window.addEventListener('load', function () {
  const toggles = [].slice.call(document.querySelectorAll('.js-toggle-trigger'));
  toggles.forEach(function (toggle) {
    toggle.addEventListener('click', toggleHandler);
  })

  if (!navigator.userAgent.match(/(Android|iPod|iPhone|iPad|BlackBerry|IEMobile|Opera Mini)/)) {
    const references = [].slice.call(document.querySelectorAll('.js-reference'));
    references.forEach(initReferenceEffect);
  }

  const swipeNodes = [].slice.call(document.querySelectorAll('.js-swipe'));
  swipeNodes.forEach(initSwipe);
  window.addEventListener('resize', function () {
    swipeNodes.forEach(initSwipe);
  })

  const menuItems = [].slice.call(document.querySelectorAll('.menu__link'));
  menuItems.forEach(function (item) {
    item.addEventListener('click', function () {
      if (item.getAttribute('href').includes(window.location.pathname)) {
        document.querySelector('.menu__link.active').classList.remove('active');
        item.classList.add('active');
      }
    })
  });

  const kontaktForm = document.getElementById('kontaktForm');
  if (kontaktForm) {
    kontaktForm.addEventListener('submit', function (e) {
      e.preventDefault();
      const request = new XMLHttpRequest();
      let urlEncodedDataPairs = [];

      const data = {
        name: document.getElementById('name').value,
        email: document.getElementById('email').value,
        subject: 'Kontaktformular upcommerce.de: ' + document.getElementById('subject').value,
        message: document.getElementById('message').value
      };

      for (let field in data) {
        urlEncodedDataPairs.push(encodeURIComponent(field) + '=' + encodeURIComponent(data[field]));
      }
      const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      request.onreadystatechange = function () {
        if (request.readyState === XMLHttpRequest.DONE) {
          let statusNode;
          if (request.status == 200) {
            statusNode = document.getElementById('kontaktForm-status-ok');
          } else {
            statusNode = document.getElementById('kontaktForm-status-error');
          }
          statusNode && statusNode.classList.remove('hidden');
        }
      }
      request.open('POST', '/post.php');
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      request.send(urlEncodedData);
    })
  }

  const goDigitalForm = document.getElementById('up-godigital');
  if (goDigitalForm) initGoDigitalFormEvents(goDigitalForm);

  const analyseForm = document.getElementById('up-analyse');
  if (analyseForm) initAnalyseFormEvents(analyseForm);

  const recruitmentForm = document.getElementById('up-recruitment');
  if (recruitmentForm) initRecruitmentFormEvents(recruitmentForm);

  const overlay = document.getElementById('contact-overlay');

  const overlayOpenTriggers = [].slice.call(document.querySelectorAll('.js-overlay-trigger'));
  overlayOpenTriggers.forEach(function (trigger) {
    if (!overlay) return;
    trigger.addEventListener('click', function () {
      overlay.classList.add('active');
    })
  });

  const overlayCloseTriggers = [].slice.call(document.querySelectorAll('.js-overlay-close'));
  overlayCloseTriggers.forEach(function (trigger) {
    if (!overlay) return;
    trigger.addEventListener('click', function (event) {
      if (!event.target.classList.contains('js-overlay-close')) return;
      overlay.classList.remove('active');
    })
  });
});

function toggleHandler(event) {
  const toggleNode = event.target.parentElement;
  toggleNode.classList.contains('active')
    ? toggleNode.classList.remove('active')
    : toggleNode.classList.add('active');
}

function initReferenceEffect(ref) {
  const container = ref.querySelector('.reference__content');

  ref.addEventListener('mouseleave', function () {
    container.style.transform = '';
    container.style.transform = '';
  });
  ref.addEventListener('mousemove', update);

  function update(e) {
    const bdst = window.scrollY;
    const bdsl = document.body.scrollLeft;
    const pageX = e.pageX;
    const pageY = e.pageY;
    const offsets = ref.getBoundingClientRect();
    const w = ref.clientWidth || ref.offsetWidth || ref.scrollWidth;
    const h = ref.clientHeight || ref.offsetHeight || ref.scrollHeight;
    const wMultiple = 320 / w;
    const offsetX = 0.52 - (pageX - offsets.left - bdsl) / w;
    const offsetY = 0.52 - (pageY - offsets.top - bdst) / h;
    const dy = (pageY - offsets.top - bdst) - h / 2;
    const dx = (pageX - offsets.left - bdsl) - w / 2;
    const yRotate = (offsetX - dx) * (0.045 * wMultiple);
    const xRotate = (dy - offsetY) * (0.022 * wMultiple);

    const styleTransform = ' perspective(' + w * 3 + 'px) rotateX(' + xRotate + 'deg) rotateY(' + yRotate + 'deg)  translateY(' + offsetY * (-2) + 'px) translateX(' + offsetX * (-2) + 'px) scale(1.06)';

    container.style.transform = styleTransform;
    container.style.webkitTransform = styleTransform;
  }
}

function initSwipe(node) {
  const content = node.querySelector('.js-swipe-content');
  const items = content.querySelectorAll('article');
  const containerWidth = content.getBoundingClientRect().width;
  const itemWidth = items[0].getBoundingClientRect().width;
  const displayedCount = Math.floor(containerWidth / itemWidth);

  let current = 0;
  let touch = null;

  const nextBtn = node.querySelector('.js-swipe-right');
  const prevBtn = node.querySelector('.js-swipe-left');

  if (items.length > displayedCount) {
    nextBtn.classList.remove('hidden');
  } else if (!nextBtn.classList.contains('hidden')) {
    nextBtn.classList.add('hidden');
  };

  nextBtn.addEventListener('click', toNextElement);
  prevBtn.addEventListener('click', toPrevElement);
  node.addEventListener('touchmove', e => {
    if (touch === null) touch = e.touches['0'].clientX;
  });
  node.addEventListener('touchend', e => {
    if (touch) {
      if (touch > e.changedTouches['0'].clientX) {
        if (current + displayedCount < items.length) {
          toNextElement();
        }
      } else {
        if (current > 0) {
          toPrevElement();
        }
      };
    }
  });

  function toPrevElement() {
    if (current === 1) {
      prevBtn.classList.add('hidden');
    }
    if (current + displayedCount === items.length) {
      nextBtn.classList.remove('hidden');
    }
    current -= 1;
    content.style.transform = `translateX(-${current * itemWidth}px)`;
  }

  function toNextElement() {
    if (current + displayedCount === items.length - 1) {
      nextBtn.classList.add('hidden');
    }
    if (current === 0) {
      prevBtn.classList.remove('hidden');
    }
    current += 1;
    content.style.transform = `translateX(-${current * itemWidth}px)`;
  }
};

function initGoDigitalFormEvents(goDigitalForm) {
  const step1 = document.getElementById('step_01');
  const step2 = document.getElementById('step_02');
  const step3 = document.getElementById('step_03');
  const step4 = document.getElementById('step_04');
  const step5 = document.getElementById('step_05');
  const step6 = document.getElementById('step_06');
  const step7 = document.getElementById('step_07');

  const alt_step3 = document.getElementById('alt_step_03');
  const alt_step4 = document.getElementById('alt_step_04');
  const alt_step5 = document.getElementById('alt_step_05');

  const success = document.getElementById('success');
  const analyse = document.getElementById('analyse');

  const stepData = {};

  function changeStep(prevStep, nextStep) {
    const hiddenClass = 'step_hidden';
    prevStep.classList.add(hiddenClass);
    nextStep.classList.remove(hiddenClass);
  }

  // step 1
  step1.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step1 = item.value;
      changeStep(step1, step2);
    })
  });

  // step 2
  step2.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step2 = item.value;
      item.value == 'Nein' ? changeStep(step2, step3) : changeStep(step2, alt_step3);
    })
  });

  // step 3
  step3.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step3 = item.value;
      item.value == 'Ja' ? changeStep(step3, step4) : changeStep(step3, alt_step3);
    })
  });

  // step 4
  step4.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step4 = item.value;
      item.value == 'Nein' ? changeStep(step4, step5) : changeStep(step4, alt_step3);
    })
  });

  // step 5
  step5.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step5 = item.value;
      item.value == 'Nein' ? changeStep(step5, step6) : changeStep(step5, alt_step3);
    })
  });

  // step 6
  step6.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step6 = item.value;
      item.value == 'weniger' ? changeStep(step6, step7) : changeStep(step6, alt_step3);
    })
  });

  // step 7
  step7.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step7 = item.value;
      if (item.value == 'weniger') {
        changeStep(step7, success);
        analyse.innerHTML = '';
      } else {
        changeStep(step7, alt_step3)
      };
    })
  });

  // alt step 3
  alt_step3.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.alt_step3 = item.value;
      changeStep(alt_step3, alt_step4);
    })
  });

  // alt step 4
  alt_step4.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.alt_step4 = item.value;
      changeStep(alt_step4, alt_step5);
    })
  });

  // alt step 5
  alt_step5.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.alt_step5 = item.value;
      changeStep(alt_step5, analyse);
      success.innerHTML = '';
    })
  });

  goDigitalForm.addEventListener('submit', function (e) {
    e.preventDefault();
    const request = new XMLHttpRequest();
    let urlEncodedDataPairs = [];

    const data = {
      ...stepData,
      subject: 'Go-Digital Anfrage upcommerce.de',
      email: document.getElementById('email').value,
      company: document.getElementById('company').value
    };

    for (let field in data) {
      urlEncodedDataPairs.push(encodeURIComponent(field) + '=' + encodeURIComponent(data[field]));
    }
    const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        let statusNode;
        if (request.status == 200) {
          statusNode = document.getElementById('godigital-status-ok');
        } else {
          statusNode = document.getElementById('godigital-status-error');
        }
        goDigitalForm.classList.add('hidden');
        statusNode && statusNode.classList.remove('hidden');
      }
    }
    request.open('POST', '/post.php');
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.send(urlEncodedData);
  })
}

function initAnalyseFormEvents(analyseForm) {
  {
    const step1 = document.getElementById('step_01');
    const step2 = document.getElementById('step_02');
    const step3 = document.getElementById('step_03');
    const step4 = document.getElementById('step_04');

    const success = document.getElementById('success');

    const stepData = {};

    function changeStep(prevStep, nextStep) {
      const hiddenClass = 'step_hidden';
      prevStep.classList.add(hiddenClass);
      nextStep.classList.remove(hiddenClass);
    }

    // step 1
    step1.querySelectorAll('input[type="radio"]').forEach(item => {
      item.addEventListener('change', function () {
        stepData.googleAnalytics = item.value;
        item.value == 'Nein' ? changeStep(step1, step3) : changeStep(step1, step2);
      })
    });

    // step 2
    step2.querySelector('[data-step="2"].next-btn').addEventListener('click', function () {
      stepData.website = document.getElementById('website').value;
      changeStep(step2, step3)
    });

    // step 3
    step3.querySelectorAll('input[type="radio"]').forEach(item => {
      item.addEventListener('change', function () {
        stepData.socialMedia = item.value;
        item.value == 'Ja' ? changeStep(step3, step4) : changeStep(step3, success);
      })
    });

    // step 4
    step4.querySelector('[data-step="4"].next-btn').addEventListener('click', function () {
      stepData.instagram = document.getElementById('instagram').value;
      stepData.facebook = document.getElementById('facebook').value;
      changeStep(step4, success);
    });

    analyseForm.addEventListener('submit', function (e) {
      e.preventDefault();
      const request = new XMLHttpRequest();
      let urlEncodedDataPairs = [];

      const data = {
        ...stepData,
        subject: 'Analyse Anfrage upcommerce.de',
        name: document.getElementById('name').value,
        email: document.getElementById('email').value
      };

      for (let field in data) {
        urlEncodedDataPairs.push(encodeURIComponent(field) + '=' + encodeURIComponent(data[field]));
      }
      const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      request.onreadystatechange = function () {
        if (request.readyState === XMLHttpRequest.DONE) {
          let statusNode;
          if (request.status == 200) {
            statusNode = document.getElementById('analyse-status-ok');
          } else {
            statusNode = document.getElementById('analyse-status-error');
          }
          analyseForm.classList.add('hidden');
          statusNode && statusNode.classList.remove('hidden');
        }
      }
      request.open('POST', '/post.php');
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      request.send(urlEncodedData);
    })
  }
}

function initRecruitmentFormEvents(recruitmentForm) {
  const step1 = document.getElementById('step_01');
  const step2 = document.getElementById('step_02');
  const step3 = document.getElementById('step_03');

  const success = document.getElementById('success');
  const noSuccess = document.getElementById('no_success');

  const stepData = {};

  function changeStep(prevStep, nextStep) {
    const hiddenClass = 'step_hidden';
    prevStep.classList.add(hiddenClass);
    nextStep.classList.remove(hiddenClass);
  }

  // step 1
  step1.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step1 = item.value;
      if (item.value == 'Ja') {
        changeStep(step1, step2)
      } else {
        changeStep(step1, noSuccess);
        success.innerHTML = '';
      }
    })
  });

  // step 2
  step2.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step2 = item.value;
      if (item.value == 'Ja') {
        changeStep(step2, step3);
      } else {
        changeStep(step2, noSuccess);
        success.innerHTML = '';
      }
    })
  });

  // step 3
  step3.querySelectorAll('input[type="radio"]').forEach(item => {
    item.addEventListener('change', function () {
      stepData.step3 = item.value;
      if (item.value == 'Ja') {
        changeStep(step3, success);
        noSuccess.innerHTML = '';
      } else {
        changeStep(step3, noSuccess);
        success.innerHTML = '';
      }
    })
  });

  recruitmentForm.addEventListener('submit', function (e) {
    e.preventDefault();
    const request = new XMLHttpRequest();
    let urlEncodedDataPairs = [];

    const data = {
      ...stepData,
      subject: 'Anfrage Foerderung-Personalmarketing upcommerce.de',
      email: document.getElementById('email').value
    };

    for (let field in data) {
      urlEncodedDataPairs.push(encodeURIComponent(field) + '=' + encodeURIComponent(data[field]));
    }
    const urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

    request.onreadystatechange = function () {
      if (request.readyState === XMLHttpRequest.DONE) {
        let statusNode;
        if (request.status == 200) {
          statusNode = document.getElementById('send-status-ok');
        } else {
          statusNode = document.getElementById('send-status-error');
        }
        recruitmentForm.classList.add('hidden');
        statusNode && statusNode.classList.remove('hidden');
      }
    }
    request.open('POST', '/post.php');
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    request.send(urlEncodedData);
  })
}